import React, { useEffect, useRef } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { srConfig } from '@config';
import sr from '@utils/sr';

const StyledAboutSection = styled.section`
  max-width: 900px;

  .inner {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-gap: 50px;

    @media (max-width: 768px) {
      display: block;
    }
  }
`;
const StyledText = styled.div`
  ul.skills-list {
    display: grid;
    grid-template-columns: repeat(2, minmax(140px, 200px));
    padding: 0;
    margin: 20px 0 0 0;
    overflow: hidden;
    list-style: none;

    li {
      position: relative;
      margin-bottom: 10px;
      padding-left: 20px;
      font-family: var(--font-mono);
      font-size: var(--fz-xs);

      &:before {
        content: '▹';
        position: absolute;
        left: 0;
        color: var(--green);
        font-size: var(--fz-sm);
        line-height: 12px;
      }
    }
  }
`;
const StyledPic = styled.div`
  position: relative;
  max-width: 300px;

  @media (max-width: 768px) {
    margin: 50px auto 0;
    width: 70%;
  }

  .wrapper {
    ${({ theme }) => theme.mixins.boxShadow};
    display: block;
    position: relative;
    width: 100%;
    border-radius: var(--border-radius);
    background-color: var(--green);

    &:hover,
    &:focus {
      background: transparent;
      outline: 0;

      &:after {
        top: 15px;
        left: 15px;
      }

      .img {
        filter: none;
        mix-blend-mode: normal;
      }
    }

    .img {
      position: relative;
      border-radius: var(--border-radius);
      mix-blend-mode: multiply;
      filter: grayscale(100%) contrast(1);
      transition: var(--transition);
    }

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: var(--border-radius);
      transition: var(--transition);
    }

    &:before {
      top: 0;
      left: 0;
      background-color: var(--navy);
      mix-blend-mode: screen;
    }

    &:after {
      border: 2px solid var(--green);
      top: 20px;
      left: 20px;
      z-index: -1;
    }
  }
`;

const About = () => {
  const data = useStaticQuery(graphql`
    query {
      avatar: file(sourceInstanceName: { eq: "images" }, relativePath: { eq: "me.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500, traceSVG: { color: "#ff00ff" }) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `);

  const revealContainer = useRef(null);

  useEffect(() => {
    sr.reveal(revealContainer.current, srConfig());
  }, []);

  // const skills = [
  //   'Minecraft Server Side and Client Side Development',
  //   'Linux Distro Development',
  //   'Node.js',
  //   'Java',
  //   'Javascript',
  //   'Dart',
  //   'Unity',
  //   'C/C++/C#',
  //   'Rust',
  //   'SQL, MongoDB, Redis',
  //   'HTMl & CSS & EJS',
  //   'Custom Mechanical Keyboard Building',
  //   'Internal Combustion Engines',
  // ];

  return (
    <StyledAboutSection id="about" ref={revealContainer}>
      <h2 className="numbered-heading">About Me</h2>

      <div className="inner">
        <StyledText>
          <div>
            <p>
              Hi! I'm Bardia, a High School Senior, Fullstack Developer, Homelabber, and Jazz
              Guitarist based in the Bay Area.
            </p>

            <p>
              Pushing the boundaries of human knowledge and technology has always been a driving
              force for me. My career goal is to work as a researcher in the field of quantum
              computing, a field that holds immense potential for revolutionizing the way we process
              information and solve complex problems. After completing my undergraduate studies at
              UC Davis, I plan to apply to Ph.D. programs, where I can obtain the credentials needed
              to work as a researcher. Ultimately, I aspire to contribute to the advancement of
              quantum computing through research, either in academia or industry. The allure of this
              field lies in its ability to push the frontiers of computing, something that has has
              always been a dream of mine.
            </p>

            <p>
              My achievements and experiences have played a pivotal role in shaping my abilities and
              work skills. As a four-year member of the marching band and jazz band, I have
              developed discipline, teamwork, and a strong work ethic. My tenure as the president of
              the Model UN for two years has cultivated my leadership, public speaking, and
              problem-solving abilities. Furthermore, my research internship at the UC Berkeley lab
              and participation in local science fairs and hackathons have provided me with hands-on
              experience in conducting research, tackling complex problems, and working
              collaboratively. During my time as a coach and coordinator at TheCoderSchool, I honed
              my skills in teaching, mentoring, and project management, further improving my ability
              to communicate complex concepts effectively.{' '}
            </p>

            <p>Feel free to reach out to me on any of my socials!</p>
          </div>
          {/* 
          <ul className="skills-list">
            {skills && skills.map((skill, i) => <li key={i}>{skill}</li>)}
          </ul> */}
        </StyledText>

        <StyledPic>
          <div className="wrapper">
            <Img fluid={data.avatar.childImageSharp.fluid} alt="Avatar" className="img" />
          </div>
        </StyledPic>
      </div>
    </StyledAboutSection>
  );
};

export default About;
